import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as path from 'path';

const routes: Routes = [
    {
      path: '',
      loadChildren: () =>
        import('./meta-search/meta-search.module').then(
          (m) => m.MetaSearchModule
        ),
    },
    {
      path: '**',
      redirectTo: ''
    },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
