<na-storefront-meta-search-loader
  *ngIf="service.isLoading$ | async"
></na-storefront-meta-search-loader>

<na-storefront-meta-search-error
  *ngIf="!(service.isLoading$ | async) && noResults"
  [errorMessage]="errorMessage"
></na-storefront-meta-search-error>

<ng-container *ngIf="searchImageData">
  <div class="search-image-wrapper">
    <img
      class="artworkdetail-image"
      [class.placeholder-image-size]="!searchImageData?.main_image"
      src="{{
        searchImageData?.main_image
          ? searchImageData?.main_image
          : placeholderImage
      }}"
      (mouseenter)="
        !searchImageData?.main_image
          ? (placeholderImageTooltip = true)
          : (placeholderImageTooltip = false)
      "
      (mouseout)="placeholderImageTooltip = false"
      (mousemove)="mouseMove($event)"
    />
    <div
      #tooltip
      class="hide-tooltip"
      [class.has-tooltip]="placeholderImageTooltip == true"
    >
      <span>Image Unavailable</span>
    </div>

    <div
      class="copyright-tooltip"
      [ngClass]="{ visibleTooltip: isTooltipVisible }"
    >
      <div class="font-medium">Copyright</div>
      <span>{{
        searchImageData?.preview_image_copyright
          ? searchImageData?.preview_image_copyright
          : dummyCopyrightText
      }}</span>
    </div>
    <span
      class="copyright-logo"
      (mouseenter)="displayTooltip('enter')"
      (mouseout)="displayTooltip('out')"
      *ngIf="searchImageData?.main_image"
      >&#169;</span
    >
  </div>

  <!--Image Title and Subtitle -->

  <div
    class="result-details-wrapper"
    [class.placeholder-margin]="!searchImageData?.main_image"
  >
    <div
      class="search-button"
      [routerLink]="[]"
      [queryParams]="{ id: undefined }"
      queryParamsHandling="merge"
    >
      <span class="small-text">Search</span
      ><img src="assets/meta-search/right-arrow.webp" class="right-arrow" />
    </div>
    <div class="image-title">
      <h1 *ngIf="searchImageData?.artwork_title">
        {{ searchImageData?.artwork_title }}
      </h1>
      <h1 class="image-year" *ngIf="searchImageData?.creation_year">
        {{ searchImageData?.creation_year }}
      </h1>
    </div>
    <div
      class="small-text"
      *ngIf="(searchImageData?.alternate_titles)!.length > 0"
    >
      <div class="grey-small-text font-medium">Also known as</div>
      <div
        class="subtitle-text"
        *ngFor="let data of searchImageData?.alternate_titles"
      >
        {{ data }}
      </div>
    </div>
    <div class="image-category image-text-title font-medium" *ngIf="sourceName">
      <a href="{{ searchImageData.weblink }}" target="_blank">{{
        sourceName
      }}</a>
    </div>
  </div>

  <!-- Jump to Section -->
  <div class="jump-to-section">
    <div class="seperator"></div>
    <div class="grey-small-text font-medium jump-to-text">
      <span>Jump to</span>
    </div>
    <div class="detail-titles font-medium">
      <span [routerLink]="[]" fragment="artwork-details" queryParamsHandling="merge">Artwork details<img src="assets/meta-search/down-arrow.webp" class="down-arrow" /></span>
      <span [routerLink]="[]" fragment="provenance" *ngIf="(searchImageData?.provenance)!.length > 0" 
        queryParamsHandling="merge">Provenance<img src="assets/meta-search/down-arrow.webp" class="down-arrow" /></span>
      <span [routerLink]="[]" fragment="exhibitions" *ngIf="(searchImageData?.exhibitions)!.length > 0" 
        queryParamsHandling="merge">Exhibitions<img src="assets/meta-search/down-arrow.webp" class="down-arrow" /></span>
      <span [routerLink]="[]" fragment="publications" queryParamsHandling="merge"
      *ngIf="
      (searchImageData?.publications)!.length > 0 ||
      (searchImageData?.publications)!.length > 0 ">Publications<img src="assets/meta-search/down-arrow.webp" class="down-arrow" /></span>
      <span [routerLink]="[]" fragment="related-artworks"
      *ngIf="nextArtworks && nextArtworks?.length" queryParamsHandling="merge">Related Artworks<img src="assets/meta-search/down-arrow.webp" class="down-arrow" /></span>
    </div>
  </div>


  <!--Image Details -->

  <div class="display-flex details-table"  id="artwork-details">
    <div class="font-medium first-column">
      <span>Artwork details</span>
    </div>
    <div class="second-column">
      <div *ngIf="searchImageData?.artist">
        <div class="grey-small-text font-medium">
          <span>Artist</span>
        </div>
        <div class="small-text" [innerHTML]="searchImageData?.artist | sanitizedMarkdown">
        </div>
        <div
          class="seperator"
          *ngIf="
            searchImageData?.medium ||
            searchImageData?.dimensions ||
            (searchImageData?.inscriptions)!.length > 0 ||
            searchImageData?.credit_line
          "
        ></div>
      </div>

      <div *ngIf="searchImageData?.medium">
        <div class="grey-small-text font-medium">
          <span>Medium</span>
        </div>
        <div class="small-text" [innerHTML]="searchImageData?.medium | sanitizedMarkdown">
        </div>
        <div
          class="seperator"
          *ngIf="
            searchImageData?.dimensions ||
            (searchImageData?.inscriptions)!.length > 0 ||
            searchImageData?.credit_line
          "
        ></div>
      </div>

      <div *ngIf="searchImageData?.dimensions">
        <div class="grey-small-text font-medium">
          <span>Dimensions</span>
        </div>
        <div class="small-text" [innerHTML]="searchImageData?.dimensions | sanitizedMarkdown">
        </div>
        <div
          class="seperator"
          *ngIf="
            (searchImageData?.inscriptions)!.length > 0 ||
            searchImageData?.credit_line
          "
        ></div>
      </div>

      <div *ngIf="(searchImageData?.inscriptions)!.length > 0">
        <div class="grey-small-text font-medium">
          <span>Signatures</span>
        </div>
        <div
          class="small-text"
          *ngFor="let inscriptions of searchImageData?.inscriptions"
          [innerHTML]="inscriptions | sanitizedMarkdown"
        >
        </div>
        <div class="seperator" *ngIf="searchImageData?.credit_line"></div>
      </div>

      <div *ngIf="searchImageData?.credit_line">
        <div class="grey-small-text font-medium">
          <span>Credit Line</span>
        </div>
        <div class="small-text"  [innerHTML]="searchImageData?.credit_line | sanitizedMarkdown">
        </div>
      </div>
    </div>
  </div>

  <div
    class="display-flex details-table light-blue-bg"
    *ngIf="(searchImageData?.provenance)!.length > 0"
    id="provenance"
  >
    <div class="font-medium first-column">
      <span>Provenance</span>
    </div>
    <div class="second-column">
      <div
        *ngFor="let provenance of searchImageData?.provenance; let last = last"
      >
        <div class="display-flex preview-wrppaer wrapper-padding wrapper-padding">
          <div class="small-text" [class.content-width]="provenance.url" [innerHTML]="provenance.name | sanitizedMarkdown">
          </div>
          <a
            *ngIf="provenance.url"
            href="{{ provenance.url }}"
            target="_blank"
            class="view-btn unstyled-anchor-link"
          >
            <img src="assets/meta-search/preview.webp" />
            <span class="view-text font-medium">VIEW</span>
          </a>
        </div>
        <div class="seperator" *ngIf="!last"></div>
      </div>
    </div>
  </div>

  <div
    class="display-flex details-table"
    *ngIf="(searchImageData?.exhibitions)!.length > 0"
    id="exhibitions"
  >
    <div class="font-medium first-column">
      <span>Exhibitions</span>
    </div>
    <div class="second-column">
      <div
        *ngFor="
          let exhibitions of searchImageData?.exhibitions;
          let last = last
        "
      >
        <div class="display-flex preview-wrppaer wrapper-padding">
          <div class="small-text" [class.content-width]="exhibitions.url" [innerHTML]="exhibitions.name | sanitizedMarkdown">
          </div>
          <a
            *ngIf="exhibitions.url"
            href="{{ exhibitions.url }}"
            target="_blank"
            class="view-btn unstyled-anchor-link"
          >
            <img src="assets/meta-search/preview.webp" />
            <span class="view-text font-medium">VIEW</span>
          </a>
        </div>
        <div class="seperator" *ngIf="!last"></div>
      </div>
    </div>
  </div>

  <div
    class="display-flex details-table light-blue-bg"
    *ngIf="
      (searchImageData?.publications)!.length > 0 ||
      (searchImageData?.publications)!.length > 0
    "
    id="publications"
  >
    <div class="font-medium first-column">
      <span>Publications</span>
    </div>
    <div class="second-column">
      <div
        *ngFor="
          let publications of searchImageData?.publications;
          let last = last
        "
      >
        <div class="display-flex preview-wrppaer wrapper-padding">
          <div class="small-text" [class.content-width]="publications.url" [innerHTML]="publications.name | sanitizedMarkdown">
          </div>
          <a
            *ngIf="publications.url"
            href="{{ publications.url }}"
            target="_blank"
            class="view-btn unstyled-anchor-link"
          >
            <img src="assets/meta-search/preview.webp" />
            <span class="view-text font-medium">VIEW</span>
          </a>
        </div>
        <div class="seperator" *ngIf="!last"></div>
      </div>
    </div>
  </div>

  <!-- <div class="display-flex details-table" *ngIf="searchImageData?.notes?.length > 0">
    <div class="font-medium first-column">
      <span>Notes</span>
    </div>
    <div class="second-column">
      <div *ngFor="let notes of searchImageData?.notes; let last = last">
        <div class="small-text">
          {{ notes }}
        </div>
        <div class="seperator" *ngIf="!last"></div>
      </div>
    </div>
  </div> -->

  <div class="next-artworks light-blue-bg" id="related-artworks">
    <ng-container *ngIf="nextArtworks && nextArtworks?.length">
      <na-storefront-controls-carousel
        title="Next artworks" 
        class="next-artworks-carousel"
      >
        <a
          *ngFor="let item of nextArtworks"
          [routerLink]="[]"
          [queryParams]="{ id: item.hash }"
          queryParamsHandling="merge"
          (click)="onArtworkClick(item.hash)"
        >
          <figure class="figure" [class.placeholder-width]="!item?.preview_image">
            <div  [class.image-container]="!item?.preview_image"  
            [class.placeholder-width]="!item?.preview_image">
            <img
              [class.placeholder-image]="!item?.preview_image"
              class="artwork-img"
              [src]="
                item?.preview_image ??
                '/assets/meta-search/meta-search-placeholder.webp'
              "
            />
            </div>
            <figcaption class="caption-text">
              <p class="text">{{ item.artwork_title }}</p>
              <p class="text grey">{{ item.creation_year }}</p>
            </figcaption>
          </figure>
        </a>
      </na-storefront-controls-carousel>
    </ng-container>
  </div>
</ng-container>
