import { HttpClientModule } from '@angular/common/http';
import { NgModule, SecurityContext, ErrorHandler } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { MarkdownModule, MarkedOptions, MarkedRenderer,  } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MetaSearchModule } from './meta-search/meta-search.module';
import { SharedModule } from './shared/shared.module';

export function markedOptionsFactory(): MarkedOptions {

  const defaults: MarkedOptions = {
    gfm: true,
    breaks: true,
    headerIds: false,
    silent: false,
  };

  const renderer = new MarkedRenderer(defaults);

  renderer.link = (href: string, title: string, text: string) => {
    if (title) {
      return `<a href="${href}" target="_blank" title="${title}" rel="noopener noreferrer">${text}</a>`;
    }
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
  };

  return {
    ...defaults,
    renderer,
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MetaSearchModule,
    SharedModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.HTML,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      }
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  exports: [SharedModule, HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
 }
