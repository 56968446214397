import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

if (environment.production) {

  Sentry.init({
    dsn: "https://ac8e03724afa49ea90928feb16d63dfd@o570661.ingest.sentry.io/4504248608292864",
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ["localhost", "https://api.navigating.art/"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.2,
  });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
