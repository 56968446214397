import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import { flatten, range, sumBy } from 'lodash-es';
import {
  BehaviorSubject, map,
  merge,
  Observable,
  of,
  shareReplay,
  Subject
} from 'rxjs';
import { catchError, debounceTime, first, switchMap,timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  APIDescription,
  ArtistQueryResult,
  DingsBumsMap,
  FlatResult,
  MetaSearchQuery,
  MetaSearchQueryResponseObject,
  MetaSearchQueryResult,
  MetaSearchDetailsQuery
} from './meta-search.interfaces';

const blacklist: string[] = [];

export type SearchFormGroup = {
  artist: FormControl;
  artwork: FormControl;
}

@Injectable()
export class MetaSearchApiService implements OnDestroy {

  // Get api base url from environment
  apiRoute = environment.metasearch.api;

  // Our connector list, fetched from the API in the constructor
  private _connectors$!: Observable<APIDescription[]>;

  // Out loaded search results
  private _searchResults$ = new BehaviorSubject<DingsBumsMap>({});

  // Results mapped to flat array
  flatResults$!: Observable<FlatResult[]>;


  // Public access to search results
  public searchResults$ = this._searchResults$.asObservable();

  //Scroll Position for meta search result
  public scrollPosition: number = 0;

  //Search Image Data results
  public searchImageData= new Subject();

  public isMetaSearchPage: boolean = true;

  public searchResultsTotal$ = this._searchResults$.pipe(
    map((results: DingsBumsMap) => sumBy(Object.values(results), (o: MetaSearchQueryResult) => o.data.length)),
  );

  public readonly artistInput = new FormControl('', [Validators.required, Validators.minLength(3)]);
  public readonly artworkInput = new FormControl('', [Validators.required, Validators.minLength(3)]);

  public _isLoading$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoading$.asObservable();
  public _isLoaded$ = new BehaviorSubject<boolean>(false);
  public isLoaded$ = this._isLoaded$.asObservable();

  public readonly searchFormGroup = new FormGroup({
    artist: this.artistInput,
    artwork: this.artworkInput,
  } as SearchFormGroup);

  public readonly apiPageSize = 100;

  constructor(private http: HttpClient) {

    this._connectors$ = this.fetchConnectors()
      .pipe(
        map((connectors: APIDescription[]) =>
          connectors.filter((connector) => !blacklist.includes(connector.path))
        ),
        shareReplay(),
      );

    this.flatResults$ = this.searchResults$
      .pipe(
        debounceTime(250),
        map((z: DingsBumsMap) => flatten(
          Object.values(z)
            .map((o: MetaSearchQueryResult) => o.data
              .map((c) => ({ ...c, connector: o.connector } as FlatResult))),
        )),
      );
  }

  getArtistsByName(artistName: string | null): Observable<ArtistQueryResult> {
    return this.http.get<ArtistQueryResult>(
      `${this.apiRoute}/artist/query?search=${artistName}&limit=5`
    ).pipe(
      catchError((err: any) => {
        console.log(err);
        return of();
      })
    );
  }

  metaSearchDetail(path: string, data: MetaSearchDetailsQuery) {
    return this.http.post<MetaSearchDetailsQuery>(`${this.apiRoute}${path}/get`, data).pipe(
    );
  }

  public updateFormGroupFromQueryParams(queryParams: Params): void {
    this.searchFormGroup.patchValue(queryParams);
  }

  public executeSearch(): void {
    if (this.searchFormGroup.invalid) {
      return;
    }

    this.searchFormGroup.markAsPristine();

    this._isLoading$.next(true);
    this._isLoaded$.next(true);

    this._searchResults$.next({});
    this._connectors$
      .pipe(
        map((connectors: APIDescription[]) => connectors
          .map((connector) => this.queryAPI(connector.path, this._searchFormGroupToMetaSearchQuery())
          .pipe(
            timeout(8000),
            catchError((error) => of({ error, connector }  as MetaSearchQueryResult)),
          ))),
        switchMap((observables: Observable<MetaSearchQueryResult>[]) => merge(...observables)),
      )
      .subscribe({
        error: (err) => {
          console.log(err);
          if (err.name === 'TimeoutError') {
            console.error('Timeout happened')
          }
        },
        next: (result: MetaSearchQueryResult) => {
          if (!result.connector) {
            return;
          }
          const connectorPath = result.connector!.path;
          const dataWithHash = this.addHashToData(result.data);
          if(this.artistInput.value && this.artworkInput.value){
            this._searchResults$.next({
              ...this._searchResults$.value,
              ...{ [connectorPath]: { ...result, data: dataWithHash } },
            });
          } else{
            this._searchResults$.next({});
          }
        },
        complete: () => {
          this._isLoaded$.next(true);
          if (environment.metasearch.recursiveSearch) {
            this._loadAdditionalData();
          } else {
            this._isLoading$.next(false);
          }
        }
      });
  }

  private _loadAdditionalData() {
    this._searchResults$
      .pipe(
        first(),
        map((o) => Object.keys(o)
          .map((key) => {
            const value = o[key];
            if (value.total > value.data.length) {
              const pagesToLoad = Math.floor(value.total / this.apiPageSize);
              if (pagesToLoad < 1) { return []; }
              return range(2, 2 + pagesToLoad - 1)
                .map((page) => this.queryAPI(value.connector!.path, this._searchFormGroupToMetaSearchQuery(), page))
            }
            return [];
          })),
        map((o) => flatten(o)),
        switchMap((observables: Observable<MetaSearchQueryResult>[]) => merge(...observables)),
      )
      .subscribe({
        next: (result: MetaSearchQueryResult) => {
          const connectorPath = result.connector!.path;
          const currentData = this._searchResults$.value[connectorPath];
          const newData = [ ...currentData.data, ...result.data];
          this._searchResults$.next({
            ...this._searchResults$.value,
            ...{ [connectorPath]: { ...result, data: newData } },
          });
        },
        complete: () => {
          this._isLoading$.next(false);
        }
      });

  }

  private _searchFormGroupToMetaSearchQuery(): MetaSearchQuery {
    return {
      artist: {
        name: this.searchFormGroup.value.artist,
      },
      artwork: {
        title: this.searchFormGroup.value.artwork,
      },
    }
  }

  // FIXME: This will disclose our sources to the world!
  fetchConnectors(): Observable<APIDescription[]> {
    return this.http.get<APIDescription[]>(`${this.apiRoute}/connectors`);
  }

  queryAPI(
    path: string,
    data: MetaSearchQuery,
    page = 1,
  ): Observable<MetaSearchQueryResult> {
    return this.http.post<MetaSearchQueryResult>(
      `${this.apiRoute}${path}/query`,
      {
        ...data,
        page,
        page_size: this.apiPageSize,
      }
    ).pipe(
      catchError((err: any) => {
        console.log(err);
        return of();
      })
    );
  }

  searchDetail(
    path: string,
    data: MetaSearchQuery
  ): Observable<MetaSearchQueryResult> {
    this._isLoading$.next(false);
    return this.http.post<MetaSearchQueryResult>
    (
      `${this.apiRoute}${path}/get`,
      data
    );
  }

  addHashToData(input: MetaSearchQueryResponseObject[]): MetaSearchQueryResponseObject[] {  
      try{
        return input.map((o) => ({
          ...o,
          hash: this.cyrb53(o.object_ref!),
        }));
      }catch(e){
        console.log(e);
        return [];
      }
  }


  cyrb53(input: string, seed = 0): string {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < input.length; i++) {
        ch = input.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return '' + 4294967296 * (2097151 & h2) + (h1>>>0);
  };

  clearSearchResults(param: any) : void{
    this._searchResults$.next({});
    if(!param['artist']) this.artistInput.setValue('');
    if(!param['artwrok']) this.artworkInput.setValue('');
  }

  ngOnDestroy(): void {
  }

}
