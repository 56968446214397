import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { paginationQueryResponse } from '../meta-search.interfaces';
export const PAGE_CUTOFF = 4;
export const MAX_PAGES = PAGE_CUTOFF * 2;
export const PAGER_DIVIDER = '...';

@Component({
  selector: 'na-storefront-controls-pagination',
  templateUrl: './controls-pagination.component.html',
  styleUrls: ['./controls-pagination.component.scss'],
})
export class ControlsPaginationComponent implements OnChanges {
  @Input()
  pagination: paginationQueryResponse = {
    page: 0,
    pages: []
  };

  @Output()
  pageChange = new EventEmitter<number>();

  currentPage: number;
  totalPages: number;
  pager: number[][] | null | (number[] | null)[];
  divider = PAGER_DIVIDER;

  hasPreviousPage: boolean = false;
  hasNextPage: boolean = true;

  constructor() {
    this.currentPage = 0;
    this.totalPages = 0;
    this.pager = [[]];
  }

  ngOnChanges() {
    this.updateState(this.pagination);
  }

  getNumericArray(numItems: number, offset = 1): number[] {
    if (!numItems) {
      return [];
    }
    return new Array(numItems).fill(null).map((val, idx) => idx + offset);
  }

  getPager(
    currentPage: number,
    totalPages: number
  ): number[][] | null | (number[] | null)[] {
    if (!totalPages || !currentPage) {
      return null;
    }
    if (totalPages === 1) {
      return null;
    }

    if (totalPages < MAX_PAGES) {
      return [this.getNumericArray(totalPages)];
    } else {
      return [
        this.getPagerStart(currentPage),
        this.getPagerMiddle(currentPage, totalPages),
        this.getPagerEnd(currentPage, totalPages),
      ].filter((i) => !!i);
    }
  }

  getPagerStart(currentPage: number): number[] | null {
    if (currentPage > PAGE_CUTOFF) {
      return [1];
    }

    if (currentPage === PAGE_CUTOFF) {
      return this.getNumericArray(PAGE_CUTOFF + 1);
    }

    if (currentPage < PAGE_CUTOFF) {
      return this.getNumericArray(PAGE_CUTOFF);
    }
    return null;
  }

  getPagerEnd(currentPage: number, totalPages: number): number[] | null {
    const upperLimit = totalPages - PAGE_CUTOFF + 1;
    if (currentPage < upperLimit) {
      return [totalPages];
    }

    if (currentPage === upperLimit) {
      return this.getNumericArray(PAGE_CUTOFF + 1, currentPage - 1);
    }

    if (currentPage > upperLimit) {
      return this.getNumericArray(PAGE_CUTOFF, upperLimit);
    }
    return null;
  }

  getPagerMiddle(currentPage: number, totalPages: number): number[] | null {
    if (totalPages === MAX_PAGES) {
      return null;
    }
    const upperLimit = totalPages - PAGE_CUTOFF + 1;

    if (currentPage > PAGE_CUTOFF && currentPage < upperLimit) {
      return this.getNumericArray(PAGE_CUTOFF, currentPage - 1);
    }
    return null;
  }

  updateState(pagination: any): void {
    if (!pagination) {
      return;
    }
    this.currentPage = pagination.page;
    this.totalPages = this.pagination.pages.length;

    this.hasPreviousPage = !(this.currentPage <= 1);
    this.hasNextPage = !(this.currentPage >= this.totalPages);

    this.pager = this.getPager(this.currentPage, this.totalPages);
  }
}
