import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'na-meta-search-terms-conditions',
  templateUrl: './meta-search-terms-conditions.component.html',
  styleUrls: ['./meta-search-terms-conditions.component.scss']
})
export class MetaSearchTermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  naviagteToLink(url: string){
    window.open(url)
  }
}
