import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, delay, distinctUntilChanged, filter, map, Observable, Subscription, switchMap } from 'rxjs';
import { MetaSearchApiService } from '../meta-search-api.service';
import { Artist, ArtistQueryResult } from '../meta-search.interfaces';

@Component({
  selector: 'na-storefront-meta-search',
  templateUrl: './meta-search.component.html',
  styleUrls: ['./meta-search.component.scss'],
})
export class MetaSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  search: boolean = false;
  isDetailPage: boolean = false;
  isMetasearchPage: boolean = true;

  @ViewChild('artistInput', { read: ElementRef })
  artistInput!: ElementRef<HTMLInputElement>;

  @ViewChild('artworkInput', { read: ElementRef })
  artworkInput!: ElementRef<HTMLInputElement>;

  @ViewChild('video', { read: ElementRef })
  video!: ElementRef<HTMLInputElement>;

  artistAutocompleteSuggestions?: Artist[] | undefined;

  autocompleteSelectedIndex: number = -1;
  autocompleteCount: number = 0;

  autocompleteSubscription!: Subscription;

  currentResultPage$!: Observable<number>;
  sourcesCount:number = 0;
  private _activatedRouteSubscription!: Subscription;

  errorMessage = new BehaviorSubject('');
  isSearchButtonClicked: boolean = false;

  constructor(
    public readonly service: MetaSearchApiService,
    public readonly ar: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    // Setup artist autocomplete
    this.autocompleteSubscription = this.service.artistInput
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(() => this.service.searchFormGroup.get('artist')!.valid),
        filter(() => !this.service.searchFormGroup.get('artist')!.pristine),
        switchMap((value) => this.service.getArtistsByName(value)),
      )
      .subscribe({
        next: (result: ArtistQueryResult) => {
          this.artistAutocompleteSuggestions = result.data || [];
          this.autocompleteSelectedIndex = -1;
          this.autocompleteCount = result.data.length || 0;
        }
      });

    this.currentResultPage$ = this.ar.queryParamMap
      .pipe(
        map((m) => m.get('page') || 1),
        map((page) => Number.isInteger(+page) ? +page : 1),
      );

      this.ar.queryParams.subscribe((params) => {
          params['id'] ? this.isDetailPage = true : this.isDetailPage = false;
          if(!params['artist'] || !params['artwork']){
            this.service.clearSearchResults(params);
            this.service.isMetaSearchPage = true;
            this.errorMessage.next('');
            this.isSearchButtonClicked = false;
          }
      });

    // Update service with query param values once on startup
    this._activatedRouteSubscription = this.ar.queryParams
      .pipe(
        distinctUntilChanged((prevParams, nextParams) => {
          const res =  prevParams['artist'] === nextParams['artist'] && prevParams['artwork'] === nextParams['artwork'];
          return res;
        }),
        delay(0),
      )
      .subscribe({
        next: (params) => {
          this.service.updateFormGroupFromQueryParams(params);
          this.service.executeSearch();
        }
      });

      this.isMetasearchPage =
      this.ar.snapshot.queryParams['artist'] &&
      this.ar.snapshot.queryParams['artwork']
        ? false
        : true;

      this.service.searchResults$.subscribe((res) => {
          const Sources = Object.values(res).filter((el) => {
            return el?.data?.length;
          });
          this.sourcesCount = Sources.length;
        })
  }

  ngAfterViewInit(): void {
    window.addEventListener('load', () => {
      this.video.nativeElement.style.width = 'calc(100% + ' + window.screen.availHeight/(window.screen.availHeight/200) + 'vh)';
    });
    window.addEventListener('resize', () => {
      this.video.nativeElement.style.width = 'calc(100% + ' + window.screen.availHeight/(window.screen.availHeight/200) + 'vh)';
    });
  }

  ngOnDestroy(): void {
    this.autocompleteSubscription?.unsubscribe();
    this._activatedRouteSubscription?.unsubscribe();
  }

  onAutocompleteSelected(selection: Artist): void {
    if (this.service.artistInput.valid) {
      this.service.artistInput.markAsPristine();
      this.artistAutocompleteSuggestions = [];
      this.autocompleteSelectedIndex = -1;
      if(this.service.artworkInput.value){
        this.router.navigate([], { queryParams: {
          ...this.service.searchFormGroup.value,
          artist: selection.name,
        }})
        .then(() => this.artworkInput.nativeElement.focus());
      } else{
        this.service.artistInput.setValue(selection.name);
        this.artworkInput.nativeElement.focus();
      }
    }
  }

  @HostListener('keydown.enter', ['$event'])
  onExecuteSearch(): void { 
    this.setErrorMessage();
    this.isSearchButtonClicked = true;
    if(this.service.searchFormGroup.invalid || this.service.searchFormGroup.pristine){
      return;
    }
    if (this.service.artistInput.valid) {
      if (this.autocompleteSelectedIndex > -1) {
        this.onAutocompleteSelected(this.artistAutocompleteSuggestions![this.autocompleteSelectedIndex]);
      } else {
        this.router.navigate([], { queryParams: this.service.searchFormGroup.value });
      }      
    this.isMetasearchPage = false;
    this.isSearchButtonClicked = false;
    }
    this.artistAutocompleteSuggestions = [];
  }

  @HostListener('keydown.arrowUp', ['$event'])
  onAutocompleteArrowUp(): void {
    if (this.autocompleteCount > 0) {
      this.autocompleteSelectedIndex = Math.max(0, this.autocompleteSelectedIndex - 1);
    }
  }

  @HostListener('keydown.arrowDown', ['$event'])
  onAutocompleteArrowDown(): void {
    if (this.autocompleteCount > 0) {
      this.autocompleteSelectedIndex = Math.min(this.autocompleteCount - 1, this.autocompleteSelectedIndex + 1);
    }
  }

  @HostListener('keydown.tab', ['$event'])
  hideArtistSuggestions(): void {
    this.artistAutocompleteSuggestions = [];
  }
  @HostListener('document:click', ['$event'])
  outsideClick(): void {
    this.hideArtistSuggestions();
  }

  @HostListener('keyup', ['$event'])
  onKeyInput(): void {
    if(this.isSearchButtonClicked && (this.service.artistInput.value || this.service.artworkInput.value)){
      this.setErrorMessage();
    }
  }

  setErrorMessage() : void{
    if(this.service.artistInput.getError('minlength') || this.service.artworkInput.getError('minlength')){
      this.errorMessage.next('Please type at least 3 characters per field to search.');
    } else if(this.service.artistInput.getError('required') || this.service.artworkInput.getError('required')){
      this.errorMessage.next('Please fill out both fields to enable a more accurate search.');
    } else {
      this.errorMessage.next('');
    }
  }

}
