<div class="main-wrapper">
  <div class="na-base-top-text-section">
    <div class="main-div">
      <div class="section-padding-right first-section">
        <h1 class="responsive-margin-bottom">Terms & Conditions</h1>
        <h3 class="font-bold">Recitals</h3>
        <p class="firt-paragraph">
          HPF Innovations GmbH, Seestr. 35-37, 14467 Potsdam (hereinafter the
          <em>“Service Provider”</em>) operates a novel technology platform,
          which provides a set of tools to support art professionals (e.g.
          artists, estates, institutes, and museums) in their daily operations
          (hereinafter the <em>“Navigating.art Platform”</em>). The use of the
          Navigating.art Platform is subject separate terms & conditions (the
          <em>“Customer Contracts”</em>) which the Service Provider executes
          with its customer when they subscribe to the royalty-based use of the
          Navigating.art Platform (these customers hereinafter the
          <em>“Customers”</em>). Additionally, the Service Provider offers a
          meta search engine (this search engine hereinafter the
          <em>“Navigating.art Meta Search Engine”</em>).
        </p>
        <p class="firt-paragraph">
          The Navigating.art Meta Search Engine allows searching for (i)
          information on artists and artworks across publicly available data
          sources (limited to sources which have permitted the Service Provider
          to access such sources via open access APIs and (ii) information on
          artists and artworks created and published by third parties via the
          Navigating.art Platform (i.e. via Navigating.art APIs) (the data
          sources under (i) and (ii) above are hereinafter referred to as the
          <em>“Third Party Sources”</em>). The use of the Navigating.art Meta
          Search Engine is granted free of charge and does not require any
          registration.
        </p>
      </div>

      <div class="image-wrapper larger-screen">
        <img src="assets/meta-search/MetaSearchExplanation_XLandL.webp" />
      </div>
      <div class="image-wrapper medium-screen">
        <img src="assets/meta-search/MetaSearchExplanation_M.webp" />
      </div>
      <div class="image-wrapper smaller-screen">
        <img src="assets/meta-search/MetaSearchExplanation_S.webp" />
      </div>
      <div class="image-text-wrapper font-medium">
        <p>Illustration of the Navigating.art Meta Search Engine</p>
      </div>

      <div class="section-padding-right">
        <p class="terms-titles">1. Scope of Applicability</p>
        <div class="display-flex">
          <div>1.1</div>
          <p class="indent">
            These Terms & Conditions set forth the conditions under which the
            Service Provider will provide the Internet user accessing the
            Navigating.art Meta Search Engine (hereinafter the <em>“User”</em>)
            with access to the Navigating.art Meta Search Engine.
          </p>
        </div>
        <div class="display-flex">
          <div>1.2</div>
          <p class="indent">
            Customer’s use of the Navigating.art Platform is subject to separate
            terms & conditions which form part of the Customer Contracts.
          </p>
        </div>
        <p class="terms-titles margin-top">2. License Grant and Right of Use</p>
        <div class="display-flex">
          <div>2.1</div>
          <p class="indent">
            The Service Provider makes available the Navigating.art Meta Search
            Engine free of charge to the User via the Service Provider’s
            website.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>2.2</div>
          <p class="indent">
            Subject to all limitations and restrictions contained in these Terms
            & Conditions, Service Provider grants the User a non-exclusive, and
            non-transferable, non-sublicensable right to access the
            Navigating.art Meta Search Engine as hosted by Service Provider and
            to use it solely to for its internal business purposes (the
            <em>“Limited License”</em>).
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>2.3</div>
          <p class="indent">
            The Service Provider is entitled to update the Navigating.art Meta
            Search Engine on a regular basis as part of its overall lifecycle
            management and product.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>2.4</div>
          <p class="indent">
            Content made available via the Third Party Sources and other third
            party content may appear on the Navigating.art Meta Search Engine or
            may be accessible via links from the Navigating.art Meta Search
            Engine. The Service Provider is not responsible for and assume no
            liability for any mistakes, misstatements of law, defamation,
            omissions, falsehood, obscenity, pornography or profanity in the
            statements, opinions, representations or any other form of content
            on the Navigating.art Meta Search Engine.
          </p>
        </div>
        <div class="display-flex">
          <div>2.5</div>
          <p class="indent">
            The User understands that the information and opinions in the Third
            Party Sources and any other third party content represent solely the
            thoughts of the author and is neither endorsed by nor does it
            necessarily reflect the Service Provider’s belief.
          </p>
        </div>
        <div class="display-flex">
          <div>2.6</div>
          <p class="indent">
            Further, the Limited License as granted hereunder is limited to the
            use of the Navigating.art Meta Search Engine and does not encompass
            any license to use any Third Party Sources and any other third party
            content. The User shall address any requests in respect of any third
            party content to the provider of any such Third Party Sources.
          </p>
        </div>
        <div class="display-flex">
          <div>2.7</div>
          <p class="indent">
            The Navigating.art Meta Search Engine contains links to Third Party
            Sources and other websites. The Service Provider is not responsible
            for the content, accuracy or opinions express in such Third Party
            Sources and other websites, and such Third Party Sources and other
            websites are not investigated, monitored or checked for accuracy or
            completeness by the Service Provider. Inclusion of any linked Third
            Party Sources and other websites on the Navigating.art Meta Search
            Engine does not imply approval or endorsement of the linked Third
            Party Sources and other websites by the Service Provider. If the
            User decides to leave our Navigating.art Meta Search Engine and
            access these Third Party Sources and other websites, the User does
            so at the User’s own risk.
          </p>
        </div>
        <p class="terms-titles margin-top">3. Non-Permitted Uses</p>
        <div class="display-flex">
          <div>3.1</div>
          <p class="indent">
            Except to the extent expressly permitted in these Terms & Conditions
            or required by law on a non-excludable basis, the Limited License
            granted by the Service Provider to the User under these Terms &
            Conditions is subject to the following prohibitions:
          </p>
        </div>
        <div class="display-flex">
          <div>a)</div>
          <p class="indent">
            the User must not permit any unauthorized person to access or use
            the Navigating.art Meta Search Engine;
          </p>
        </div>
        <div class="display-flex">
          <div>b)</div>
          <p class="indent">
            the User must not use the Navigating.art Meta Search Engine to
            provide services to third parties, unless otherwise specified in the
            Terms & Conditions;
          </p>
        </div>
        <div class="display-flex">
          <div>c)</div>
          <p class="indent">
            the User must not make any alteration to the Navigating.art Meta
            Search; and
          </p>
        </div>
        <div class="display-flex">
          <div>d)</div>
          <p class="indent">
            the User will not, directly or indirectly: (i) reverse engineer,
            decompile, disassemble or otherwise attempt to discover the source
            code, object code or underlying structure, ideas, know-how or
            algorithms relevant to the Navigating.art Meta Search Engine; (ii)
            modify, translate or create derivative works based on the
            Navigating.art Meta Search Engine (except to the extent expressly
            permitted by the Service Provider).
          </p>
        </div>
        <div class="display-flex">
          <div>3.2</div>
          <p class="indent">
            The User must not use the Navigating.art Meta Search Engine in any
            way that
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>a)</div>
          <p class="indent">
            is unlawful, illegal, fraudulent or harmful, or is connected to such
            behavior;
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>b)</div>
          <p class="indent">
            causes, or may cause, damage to the Navigating.art Meta Search
            Engine or impairment of the availability or accessibility of the
            Navigating.art Meta Search Engine, including through, but not
            limited to, the promotion or distribution of, or infection with, any
            viruses, worms, spyware, adware or other harmful or malicious or
            not-fit-for-purpose software, programs, routines, applications or
            technologies.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>3.3</div>
          <p class="indent">
            Service Provider has the right (but not the obligation) to suspend
            access to the Navigating.art Meta Search Engine or remove any data
            or content transmitted via the Navigating.art Meta Search Engine
            without liability (i) if Service Provider reasonably believes that
            the Navigating.art Meta Search Engine is being used in violation of
            these Terms & Conditions or applicable law, (ii) if requested by a
            law enforcement or government agency or otherwise to comply with
            applicable law, provided that Service Provider shall use
            commercially reasonable efforts to notify the User prior to
            suspending the access to the Navigating.art Meta Search Engine as
            permitted under these Terms & Conditions.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>3.4</div>
          <p class="indent">
            The User agrees to defend, indemnify, and hold Service Provider and
            its officers, directors, employees, consultants, and agents harmless
            from and against any and all damages, costs, liabilities, expenses
            (including, without limitation, reasonable attorneys’ fees), and
            settlement amounts incurred in connection with any claim arising
            from or relating to the User’s: breach of any of its obligations
            under these Terms & Conditions and, in particular, any use of the
            Navigating.art Meta Search Engine in violation of these Terms &
            Conditions or applicable law by the User or any of its users.
          </p>
          <br />
        </div>

        <p class="terms-titles margin-top">4. No Service Fees</p>
        <div class="display-flex">
          <p>
            The User may use the Navigating.art Meta Search Engine free of
            charge.
          </p>
        </div>

        <p class="terms-titles margin-top">5. Availability</p>
        <div class="display-flex">
          <div>5.1</div>
          <p class="indent">
            The Service Provider does not warrant any specific availability of
            the Navigating.art Meta Search Engine.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>5.2</div>
          <p class="indent">
            The Service Provider reserves the right to suspend and/or
            discontinue the Navigating.art Meta Search Engine at any time at its
            discretion.
          </p>
          <br />
        </div>

        <p class="terms-titles margin-top">6. IP Ownership</p>
        <div class="display-flex">
          <div>6.1</div>
          <p class="indent">
            The User acknowledges that, subject to the Limited Licenses granted
            herein, the User has no ownership interest in the Navigating.art
            Meta Search Engine or Service Provider materials provided to the
            User.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>6.2</div>
          <p class="indent">
            Service Provider will own all rights, title, and interest in such
            Software and Service Provider materials, subject to any limitations
            associated with intellectual property rights of third parties.
            Service Provider reserves all rights not specifically granted
            herein.
          </p>
          <br />
        </div>

        <p class="terms-titles margin-top">7. Data Protection</p>
        <div class="display-flex">
          <div>7.1</div>
          <p class="indent">
            Reference is made to the Service Provider’s
            <a
              href="https://navigating.art/en/privacy-policy"
              target="_blank"
              class="footer-link footer-link-padding"
              >Privacy Policy</a
            >
            .
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>7.2</div>
          <p class="indent">
            Before entering its data and information to the Navigating.art Meta
            Search Engine (the “User Data”), the User shall be obliged to check
            the same for viruses or other harmful components and to use state of
            the art anti-virus programs for this purpose.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.3</div>
          <p class="indent">
            In addition, the User itself shall be responsible for the entry and
            the maintenance of its User Data. The Service Provider shall not be
            obliged to create a back-up copy of the User Data.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.4</div>
          <p class="indent">
            The User will own all right, title, and interest in such User Data.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.5</div>
          <p class="indent">
            The User grants to Service Provider a non-exclusive, royalty-free
            license to access, use, reproduce, modify, perform, display and
            distribute User Data as is reasonable or necessary for Service
            Provider to perform or provide the Navigating.art Meta Search Engine
            and the Navigating.art Platform.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.6</div>
          <p class="indent">
            The User is solely responsible for all User Data, in particular that
            its transfer and use in accordance with these Terms & Conditions
            does not violate any applicable laws, including data protection
            laws, and/or intellectual property rights of third parties. In
            particular, the User guarantees that it holds the necessary rights
            to upload any artworks on the Navigating.art Meta Search Engine.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.7</div>
          <p class="indent">
            The User shall indemnify and defend the Service Provider and each of
            its past, present and future directors, officers, employees and
            agents, in each case, in their respective capacities as such, and
            each of the heirs, executors, successors and assigns of any of the
            foregoing, from and against any and all third party suit, claim,
            action or demand to the extent relating to, arising out of or
            resulting from, directly or indirectly, any hosting of the User
            Data. This indemnity shall only apply in case the User Data (i.e.
            content uploaded by the User to the Navigating.art Meta Search
            Engine) violates any copyright, privacy or trademark right.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.8</div>
          <p class="indent">
            The User acknowledges that the Service Provider does not exercise
            any control over User Data and that it acts as a mere or passive
            conduit in transmitting and handling User Data. Upon specific
            request, the Service Provider may upload User Data on behalf of the
            User.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>7.9</div>
          <p class="indent">
            The term “User Data” shall not include any data and information
            entered to the Navigating.art Meta Search Engine by the Service
            Provider, e.g. data complementing the User Data (the “Provider
            Data”). The Service Provider will own all right, title, and interest
            in such Provider Data.
          </p>
          <br />
        </div>

        <p class="terms-titles margin-top">8. Limitation of Liability</p>
        <div class="display-flex">
          <div>8.1</div>
          <p class="indent">
            In case of wilful misconduct, the Service Provider shall be liable
            according to the statutory provisions of applicable law.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>8.2</div>
          <p class="indent">
            In case of gross negligence, the Service Provider shall be liable
            according to the statutory provisions of applicable law.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>8.3</div>
          <p class="indent">
            Service Provider shall not be liable in case of ordinary negligence.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>8.4</div>
          <p class="indent">
            The aforementioned limitations of section 8.3 do not apply to
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>a)</div>
          <p class="indent">
            damages resulting from injury to life, body or health;
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>b)</div>
          <p class="indent">
            liability pursuant to the German Product Liability Act
            (“Produkthaftungsgesetz”); the assumption of a guarantee for the
            condition of goods and/or work or
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>c)</div>
          <p class="indent">
            fraudulent concealment of defects by the Service Provider.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>8.5</div>
          <p class="indent">
            The aforementioned limitations of liability shall, subject to the
            provisions of section 8.4, apply to (i) any liability claims for
            whatever legal reason but in particular due to impossibility,
            default, defective or incorrect delivery, breach of contract, breach
            of obligations in contractual negotiations and tort, as far as such
            claims are subject to fault, and (ii) any breach of duty by
            vicarious agents or any other person for whose conduct the Service
            Provider can be held liable according to the statutory provisions of
            applicable law.
          </p>
          <br />
        </div>

        <p class="terms-titles margin-top">9. Final Provision</p>
        <div class="display-flex">
          <div>9.1</div>
          <p class="indent">
            Each Party shall bear its own costs incurred in connection with the
            execution and performance of these Terms & Conditions, unless
            expressly agreed otherwise in these Terms & Conditions.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>9.2</div>
          <p class="indent">
            These Terms & Conditions fully reflect the Terms & Conditions
            between the Parties regarding the subject matter; no oral or other
            side Terms & Conditions exist. Unless expressly agreed otherwise in
            these Terms & Conditions, all previous Terms & Conditions between
            the Parties regarding the subject matter shall be fully replaced by
            Terms & Conditions with effect from the effective date of these
            Terms & Conditions.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>9.3</div>
          <p class="indent">
            These Terms & Conditions shall be governed by the laws of the
            Federal Republic of Germany, excluding the conflict of laws rules of
            private international law. The applicability of the UN Convention on
            Contracts for the International Sale of Goods (CISG) is excluded.
          </p>
          <br />
        </div>

        <div class="display-flex">
          <div>9.4</div>
          <p class="indent">
            Exclusive place of jurisdiction for all disputes arising out of or
            in connection with these Terms & Conditions shall be Berlin, unless
            otherwise required by mandatory law.
          </p>
          <br />
        </div>
        <div class="display-flex">
          <div>9.5</div>
          <p class="indent">
            Should any provision of these Terms & Conditions be or become
            invalid or unenforceable in whole or in part, the validity of the
            remaining provisions of these Terms & Conditions shall not be
            affected. The same shall apply if and insofar as a gap in these
            Terms & Conditions become apparent. In place of the invalid or
            unenforceable provision or to fill the gap, an appropriate provision
            shall apply which, as far as legally possible, comes closest to or
            corresponds to what the Parties economically intended or would have
            intended according to the spirit and purpose of these Terms &
            Conditions, had they considered this point.
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</div>
