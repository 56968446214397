<div class="paginator-container font-medium">
  <ng-container *ngIf="pager && pager.length >= 1">
    <button
      aria-label="Go to previous page"
      type="button"
      (click)="pageChange.emit(currentPage - 1)" 
      [disabled]="!hasPreviousPage"
    > 
      <i class="icon-arrow_left"></i>    
    </button> 

    <ng-container *ngFor="let item of pager; let isLast = last">
      <ng-container *ngFor="let page of item; let i = index"> 
        <button
          attr.aria-label="Go to page {{ page }}"
          type="button"
          (click)="pageChange.emit(page)" 
          [class.inactive]="currentPage !== page"
        >
          {{ page }}
        </button>
      </ng-container>
      <span class="pager-divider" *ngIf="!isLast">{{ divider }}</span>
    </ng-container> 

    <button
      aria-label="Go to next page"
      type="button"
      (click)="pageChange.emit(currentPage + 1)"
      [disabled]="!hasNextPage"
    >
      <i class="icon-arrow_right"></i>
    </button>
  </ng-container>
</div>
