import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'na-storefront-meta-search-bottom-information',
  templateUrl: './meta-search-bottom-information.component.html',
  styleUrls: ['./meta-search-bottom-information.component.scss']
})
export class MetaSearchBottomInformationComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }
 
}
