<ng-container *ngIf="service.searchResults$ | async | keyvalue as results">
  <video #video id="video" [class.disabled]="results.length" [autoplay]="true" [muted]="true" loop> 
    <source
      src="assets/meta-search/meta-search-header-background.mp4"  
    />
  </video>

  <section [class.section-height]="service.isMetaSearchPage"  [class.results-page-section-min-height]="!isMetasearchPage">  
    <div [class.section-padding]="!isDetailPage" *ngIf="!isDetailPage">
      <header class="overview-title" [class.has-results]="results.length > 1">
        <h3 *ngIf="results.length > 1; else defaultHeader">
          Showing {{ service.searchResultsTotal$ | async }} Artworks from
          {{ sourcesCount }} Sources:
        </h3>
        <ng-template #defaultHeader>
          <h1 class="meta-search-title">
            The most convenient search tool for art information
          </h1>
          <p class="meta-search-sub-title font-medium">
            Navigating.art’s search tool combines multiple data sources into
            one, making your research easier & more reliable.
          </p>
        </ng-template>
      </header>

      <form [formGroup]="service.searchFormGroup" [class.header-bottom-margin]="results.length > 1">
        <span class="font-medium">{{
          artistInput?.value ? 'Artist Name' : '&nbsp;'
        }}</span>
        <span class="font-medium">{{
          artworkInput.value ? 'Artwork Title' : '&nbsp;'
        }}</span>
        <span>&nbsp;</span>

        <div>
          <input
            #artistInput
            type="text"
            id="artist"
            placeholder="Type an artist’s name..."
            formControlName="artist"
            class="artist-name text-inputs font-medium"
            [class.input-bg]="!service.isMetaSearchPage"
          />

          <ul
            class="auto-complete font-medium"
            *ngIf="artistAutocompleteSuggestions as suggestions"
          >
            <li
              [class.selected]="idx === autocompleteSelectedIndex"
              *ngFor="let suggestion of suggestions; index as idx"
              (click)="onAutocompleteSelected(suggestion)"
            >
              {{ suggestion.name }}
            </li>
          </ul>
        </div>

        <div>
          <input
            type="text"
            class="artwork-input text-inputs font-medium"
            #artworkInput
            placeholder="Type an artwork title..."
            formControlName="artwork"
            [class.input-bg]="!service.isMetaSearchPage"
          />
        </div>

        <div class="button-wrapper search-btn-wrapper">
          <button
            class="search-btn na-button font-medium"
            type="button"
            [class.disable-search-btn]="service.searchFormGroup.invalid || service.searchFormGroup.pristine"
            (click)="onExecuteSearch()"
          >
            Search
          </button>
        </div>
        <ng-container>
          <na-storefront-meta-search-error *ngIf="service.searchFormGroup.invalid && (errorMessage | async); else noError" 
            [errorMessage]="errorMessage | async" [isFieldValidationError]="true" class="form-validation-error">
          </na-storefront-meta-search-error>
          <ng-template #noError>
            <div class="blank-space"></div>
          </ng-template> 
        </ng-container>
      </form>
    </div>

    <ng-container *ngIf="ar.queryParams | async as queryParams">
      <na-storefront-meta-search-result-detail
        *ngIf="queryParams['id']"
      ></na-storefront-meta-search-result-detail>
    </ng-container>

    <ng-container *ngIf="ar.queryParams | async as queryParams">
      <na-storefront-meta-search-results
        *ngIf="!queryParams['id'] && queryParams['artist'] && queryParams['artwork']"
        [class.is-loading]="service.isLoading$ | async"
        [page$]="currentResultPage$"
      ></na-storefront-meta-search-results>
    </ng-container>
  </section>
</ng-container>

