<na-storefront-meta-search-loader
  *ngIf="service.isLoading$ | async"
></na-storefront-meta-search-loader>

<ng-container *ngIf="renderResults$ | async as results">
    <ng-container *ngIf="results.length || (!results.length && (showNoResultInfo$ | async))">
    <div class="filters" id="filterType" #filterType>
      <div class="icon font-medium">
        <img src="/assets/meta-search/meta-search-filter-icon.png" />
        Filter by type
      </div>
      <ul *ngIf="renderOrganizationTypes$ | async as renderOrganizationTypes">
        <li
          class="pointer-cursor"
          *ngFor="let orgType of renderOrganizationTypes.types; let i = index"
          [class.active]="renderOrganizationTypes.selected.includes(orgType)"
          (click)="toggleOrganizationFilter(orgType, renderOrganizationTypes)"
        >
          {{ orgType }}
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="results.length; else noResults">
    <div class="results" *ngIf="page$ | async as currentPage">
      <div
        *ngFor="let data of results; trackBy: trackByResult; let i = index"
        [routerLink]="[]"
        [queryParams]="{ id: data.hash, source: data.connector.name }"
        queryParamsHandling="merge"
        (click)="showMetaSearchDetail()"
        class="result-wrapper"
      >
        <div  class="image-container" [class.placeholder-width]="!data?.preview_image"  [class.image-container]="!data?.preview_image">
            <img
              [class.placeholder-image]="!data?.preview_image"
              #image
              [src]="
              data?.preview_image ??
              '/assets/meta-search/meta-search-placeholder.webp'
            "
            (error)="
              image.src = '/assets/meta-search/meta-search-placeholder.webp'
            "
            loading="lazy"
            alt="Previw image of {{ data.artwork_title }}"
            />
            </div>
        <div
          class="org-type-icon"
          [style.background-color]="
            organziationIconBackgroundColorMap[data.connector.organization_type]
          "
        >
          {{ organziationIconTextMap[data.connector.organization_type] }} 
        </div>

        <div class="texts font-medium">
          <h4 class="font-medium">{{ data.connector.name }}</h4>
          <h3 class="font-medium">{{ data.artwork_title }}</h3>
          <p class="artist">{{ data.artist }}</p>
          <p class="year">
            {{ data?.creation_year ? data?.creation_year : '' }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="pages$ | async as pages">
      <na-storefront-controls-pagination
        [pagination]="{ page: currentPage, pages: pages }"
        (pageChange)="goToPage($event)"
      ></na-storefront-controls-pagination>
    </div>
  </ng-container>

  <ng-template #noResults>
    <na-storefront-meta-search-error
      *ngIf="showNoResultInfo$ | async"
      [errorMessage]="errorMessage"
    ></na-storefront-meta-search-error>
  </ng-template> 
