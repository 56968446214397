import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MarkdownService } from "ngx-markdown";

@Pipe({
  name: "sanitizedMarkdown",
})
export class SanitizedMarkdownPipe implements PipeTransform {
  constructor(
    private readonly srv: MarkdownService,
    private readonly san: DomSanitizer
  ) {}

  transform(text: string): any {
    return this.srv.parse(this.san.sanitize(SecurityContext.HTML, text) || "");
  }
}
