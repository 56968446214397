<div class="full-width-section next-artworks-container">
  <div
    class="headline grid"
    *ngIf="title || (areArrowsActive && arrowsMode === 'top')"
  >
    <h4 class="title span-11 font-medium" *ngIf="title">{{ title }}</h4>
    <div 
      class="icons span-1 start-12 h2"  
      *ngIf="areArrowsActive && arrowsMode === 'top'"  
    >
      <button 
        aria-label="Scroll to previous page of items"
        (click)="scrollItems(-1)"
        [disabled]="startReached"
      >   
        <i class="icon-arrow_left"></i>
      </button>
      <button
        aria-label="Scroll to next page of items"
        (click)="scrollItems(+1)"
        [disabled]="endReached"
      >
        <i class="icon-arrow_right"></i>
      </button>
    </div>
  </div>

  <div class="side-arrows-container">
    <button
      aria-label="Scroll to previous page of items"
      (click)="scrollItems(-1)"
      [disabled]="startReached"
      *ngIf="areArrowsActive && arrowsMode === 'side'"
    >
      <i class="icon-arrow_left"></i>
    </button>
    <div class="carousel" #carousel>
      <ng-content></ng-content>
      <!-- <div class="space"></div> -->
    </div>
    <button
      aria-label="Scroll to next page of items"
      (click)="scrollItems(+1)"
      [disabled]="endReached"
      *ngIf="areArrowsActive && arrowsMode === 'side'"
    >
      <i class="icon-arrow_right"></i>
    </button>
  </div>
</div>
