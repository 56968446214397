import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MetaSearchApiService } from "./meta-search-api.service";
import { MetaSearchResultDetailComponent } from "./meta-search-result-detail/meta-search-result-detail.component";
import { MetaSearchResultsComponent } from "./meta-search-results/meta-search-results.component";
import { MetaSearchRoutingModule } from "./meta-search-routing.module";
import { MetaSearchComponent } from "./meta-search/meta-search.component";
import { NgxPaginationModule } from "ngx-pagination";
import { MetaSearchLoaderComponent } from "./meta-search-loader/meta-search-loader.component";
import { MetaSearchErrorComponent } from "./meta-search-error/meta-search-error.component";
import { ControlsPaginationComponent } from "./controls-pagination/controls-pagination.component";
import { ControlsCarouselComponent } from "./controls-carousel/controls-carousel.component";
import { SharedModule } from "../shared/shared.module";
import { MetaSearchBottomInformationComponent } from "./meta-search-bottom-information/meta-search-bottom-information.component";
import { MarkdownModule } from "ngx-markdown";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    MetaSearchComponent,
    MetaSearchResultsComponent,
    MetaSearchResultDetailComponent,
    MetaSearchLoaderComponent,
    MetaSearchErrorComponent,
    ControlsPaginationComponent,
    ControlsCarouselComponent,
    MetaSearchBottomInformationComponent,
  ],
  imports: [
    CommonModule,
    MetaSearchRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SharedModule,
    MarkdownModule,
    RouterModule,
  ],
  providers: [MetaSearchApiService],
  exports: [MetaSearchLoaderComponent],
})
export class MetaSearchModule {}
