<div id="footer" class="na-footer-grid na-black-bg white-text">
  <img
    (click)="redirectTo()"
    src="assets/meta-search/Long Logo Blue.webp"
    class="footer-logo"
    width="172"
    height="50"
    alt="Logo"
    loading="lazy"
  />
  <div class="footer-content">
    <p class="footer-text">
      A single search engine to query information on thousands of artists and
      millions of artworks. The easiest way to use open access information and
      dedicated catalogue raisonne publications.
    </p>
  </div>
  <div class="footer-links-container">
    <div class="footer-links">
      <a
        href="https://navigating.art/en/imprint"
        target="_blank"
        class="footer-link footer-link-right-boder"
        >Imprint</a
      >
      <a
        href="/terms-and-conditions"
        target="_blank"
        class="footer-link footer-link-right-boder footer-link-padding"
        >Terms & Conditions</a
      >
      <a
        href="https://navigating.art/en/privacy-policy"
        target="_blank"
        class="footer-link footer-link-padding"
        >Privacy</a
      >
    </div>
    <p class="footer-year-text">2022 Navigating.art</p>
  </div>
  <button class="back-to-top-btn" (click)="backToTop()">
    <img
      height="24"
      width="24"
      src="assets/meta-search/back-to-top-arrow.webp"
    />
  </button>
</div>
