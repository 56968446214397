import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  Params,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import { range, sortBy, uniq } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { MetaSearchApiService } from '../meta-search-api.service';
import { FlatResult, OrgTypeEnum } from '../meta-search.interfaces';

type RenderOrganizationType = {
  types: OrgTypeEnum[];
  selected: OrgTypeEnum[];
};

@Component({
  selector: 'na-storefront-meta-search-results',
  templateUrl: './meta-search-results.component.html',
  styleUrls: ['./meta-search-results.component.scss'],
})
export class MetaSearchResultsComponent implements OnInit {
  @Input()
  page$!: Observable<number>;

  @Input()
  pageSize: number = 20;

  pages$!: Observable<number[]>;

  organisationTypes$!: Observable<OrgTypeEnum[]>;

  orgTypes: OrgTypeEnum[] = [];

  queryParams: OrgTypeEnum[] = [];

  currentPage: number = 1;

  isPageChanged: boolean = false;

  errorMessage: string =
    'There are no results for this search, please adjust the terms.';
    
  isFilterChanged: boolean = false;

  private _selectedOrganisationTypes$ = new BehaviorSubject<OrgTypeEnum[]>([]);
  public selectedOrganisationTypes$ = this._selectedOrganisationTypes$;

  public renderOrganizationTypes$!: Observable<RenderOrganizationType>;
  public renderResults$!: Observable<FlatResult[]>;

  public organziationIconTextMap: { [key in OrgTypeEnum]: string } = {
    'Catalogue Raisonné': 'CR',
    Institution: 'IN',
    Museum: 'M',
  };

  public organziationIconBackgroundColorMap: { [key in OrgTypeEnum]: string } =
    {
      'Catalogue Raisonné': '#9f9f00',
      Institution: '#cd59f7',
      Museum: '#577de0',
    };

  public showNoResultInfo$: Observable<boolean> = combineLatest([
    this.service.isLoading$,
    this.service.isLoaded$,
    this.service.searchResultsTotal$,
  ]).pipe(
    map(([isLoading, isLoaded, searchResultsTotal]) => {
      return !isLoading && isLoaded && searchResultsTotal === 0;
    })
  );

  @ViewChild('filterType') filterType: ElementRef | any;

  constructor(
    public readonly service: MetaSearchApiService,
    private readonly router: Router,
    private readonly ar: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.organisationTypes$ = this.service.flatResults$.pipe(
      map((results) => results.map((o) => o.connector.organization_type)),
      map((orgTypes) => uniq(orgTypes).sort())
    );

    this.renderOrganizationTypes$ = combineLatest([
      this.organisationTypes$,
      this.selectedOrganisationTypes$,
    ]).pipe(
      map(
        ([types, selected]) => ({ types, selected } as RenderOrganizationType)
      )
    );

    this.renderResults$ = combineLatest([
      this.service.flatResults$,
      this.selectedOrganisationTypes$,
      this.page$,
    ]).pipe(
      debounceTime(250),
      map(([results, selectedOrgTypes, page]) =>
        sortBy(results, 'score')
          .reverse()
          .filter((result, index) => {
            if (
              selectedOrgTypes.length &&
              !selectedOrgTypes.includes(result.connector.organization_type)
            ) {
              return false;
            }
            return true;
          })
          .slice((page - 1) * this.pageSize, page * this.pageSize)
      ),tap ((res: FlatResult[]) => {
        if (res) {
          this.service.isMetaSearchPage = false;
        }
      })
    );

    this._selectedOrganisationTypes$.next([]);

    this.currentPage = this.ar.snapshot.queryParams['page']
      ? +this.ar.snapshot.queryParams['page']
      : this.currentPage;

    this.ar.queryParams.subscribe((res) => {
      if(this.isFilterChanged){
        setTimeout(() => {
          window.scrollTo({
            top: 0
          });
          this.isFilterChanged = false;
        }, 600);
      } else {
      if (!res['id'] && this.isPageChanged === false) {
        setTimeout(() => {
          window.scrollTo({
            top: this.service?.scrollPosition,
            behavior: 'smooth',
          });
        }, 600);
      } else if (!res['id'] && this.isPageChanged === true) {
        setTimeout(() => {
          const filtersElement: any =
            window.pageYOffset +
            this.filterType?.nativeElement.getBoundingClientRect().y;
          window.scrollTo({ top: filtersElement, behavior: 'smooth' });
        }, 600);
      } else {
        this.isPageChanged = false;
      }
    }
      const params = res['orgType'];
      const paramsArray = params?.split(',');
      this.queryParams = [];
      paramsArray?.map((res: OrgTypeEnum) => this.queryParams.push(res));

      if (this.queryParams?.length) {
        this._selectedOrganisationTypes$.next(uniq([...this.queryParams]));
      }
    });

    this.paginationByFilter();
  }

  showMetaSearchDetail(): void {
    this.service.scrollPosition = window.pageYOffset;
    this.service._isLoading$.next(true);
  }

  toggleOrganizationFilter(
    orgType: OrgTypeEnum,
    renderOrganizationTypes: RenderOrganizationType
  ) {
    this.isFilterChanged = true;
    this.orgTypes = [];
    this.orgTypes.push(orgType);
    this.addQueryParams();
    this.paginationByFilter();
    if (renderOrganizationTypes.selected.includes(orgType)) {
      this._selectedOrganisationTypes$.next(
        this.queryParams.filter((o) => o !== orgType)
      );
    } else {
      this._selectedOrganisationTypes$.next(
        uniq([...this.queryParams, orgType])
      );
    }
    setTimeout(() => {
      this.currentPage = 1;
      this.router.navigate([], {
        queryParams: { page: 1 },
        queryParamsHandling: 'merge',
      });
    });
  }

  trackByResult(index: number, result: FlatResult): string {
    return `${result.connector}-${result.artist ?? 'at' + index}-${
      result.artwork_title ?? 'aw' + index
    }`; 
  }

  addQueryParams(): void {
    this._selectedOrganisationTypes$.subscribe((res: any) => {
      if (res.length > 0) {
        const response = res.join(',');
        this.router.navigate([], {
          queryParams: { orgType: response },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([], {
          queryParams: { orgType: undefined },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.navigateSelf({ page });
  }

  navigateSelf(
    queryParams: Params = {},
    queryParamsHandling: QueryParamsHandling = 'merge',
    replaceUrl = false
  ): Promise<boolean> {
    this.isPageChanged = true;
    this.service.scrollPosition = 0;
    return this.router.navigate([], {
      queryParams,
      queryParamsHandling,
      replaceUrl,
    });
  }

  paginationByFilter() {
    this.pages$ = combineLatest([
      this.service.flatResults$,
      this.selectedOrganisationTypes$,
    ]).pipe(
      map(([results, selectedOrgTypes]) => {
        let filteredResults: FlatResult[] = results.filter((result) => {
          if (
            selectedOrgTypes.length &&
            !selectedOrgTypes.includes(result.connector.organization_type)
          ) {
            return false;
          }
          return true;
        });
        return range(1, Math.ceil(filteredResults.length / this.pageSize) + 1);
      })
    );
  }
}
