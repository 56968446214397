import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'na-storefront-meta-search-error',
  templateUrl: './meta-search-error.component.html',
  styleUrls: ['./meta-search-error.component.scss']
})
export class MetaSearchErrorComponent implements OnInit {

  @Input()
  errorMessage!: string | null; 

  @Input() 
  isFieldValidationError! : boolean;

  public isDetailPage : boolean = false;

  constructor(public readonly ar: ActivatedRoute) {
    this.ar.queryParams.subscribe((params) => {
      params['id'] ? this.isDetailPage = true : this.isDetailPage = false;
  });
   }

  ngOnInit(): void {
  }

}
