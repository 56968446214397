import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export type ArrowModes = 'top' | 'side';

@Component({
  selector: 'na-storefront-controls-carousel',
  templateUrl: './controls-carousel.component.html',
  styleUrls: ['./controls-carousel.component.scss'],
})
export class ControlsCarouselComponent
  implements AfterContentInit, OnDestroy, AfterContentChecked
{
  @ViewChild('carousel', { static: true }) carousel!: ElementRef;

  @Input()
  title: string = 'Next artworks';

  @HostBinding('class')
  @Input()
  arrowsMode: ArrowModes = 'top';

  @Input()
  disableArrows: boolean = false;

  @Input()
  disableScrollReset = false;

  @Output()
  scrolled = new EventEmitter<boolean>();

  @HostBinding('class.scrollable')
  isScrollable = false;

  areArrowsActive: boolean = true;
  startReached = true;
  endReached = false;
  maxScroll: number = 20;
  numberOfItemsToScroll: number = 1;
  currentItemIdx = 0;
  content: string = '';

  images: HTMLElement[] = [];

  scrollSubscription: Subscription = new Subscription();

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    this.images = this.getElementsArray();

    this.scrollSubscription = fromEvent(this.carousel.nativeElement, 'scroll')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.currentItemIdx = this.images.findIndex(
          (o: HTMLElement) =>
            o.offsetLeft -
              this.carousel.nativeElement.offsetLeft -
              this.carousel.nativeElement.scrollLeft >=
            0
        );
        this.updateScrollLimits();
        this.scrolled.emit(true);
      });

    this.content = this.carousel.nativeElement.innerHTML;
  }

  ngAfterContentChecked(): void {
    const currentContent = this.carousel.nativeElement.innerHTML;
    if (this.content !== currentContent) {
      this.content = currentContent;
      this.images = this.getElementsArray();
      if (!this.disableScrollReset) {
        this.setScroll();
      }
    }

    /* istanbul ignore else */
    if (this.carousel) {
      this.setScrollableState();
    }
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }

  updateNumberOfItemsToScroll(images: HTMLElement[]): void {
    if (!images) {
      return;
    }
    this.numberOfItemsToScroll = images.findIndex(
      (o: HTMLElement) =>
        o.offsetLeft + o.offsetWidth > this.carousel.nativeElement.clientWidth
    );
  }

  @HostListener('window:resize')
  onResize() {
    this.updateScrollLimits();
    this.updateNumberOfItemsToScroll(this.images);
    this.setScrollableState();
  }

  centerElement(idx: number) {
    const centerElement: HTMLElement = this.images[idx];
    const left =
      centerElement.offsetLeft -
      this.carousel.nativeElement.clientWidth / 2 +
      centerElement.clientWidth / 2;
    if (this.carousel.nativeElement.scrollTo) {
      this.carousel.nativeElement.scrollTo({
        left,
        behavior: 'smooth',
      });
    }
  }

  setScrollableState(): void {
    this.isScrollable =
      this.carousel.nativeElement.scrollWidth >
      this.carousel.nativeElement.clientWidth;
    this.areArrowsActive = this.isScrollable && !this.disableArrows;
  }

  setScroll(left = 0) {
    this.carousel.nativeElement.scrollLeft = left;
    this.updateScrollLimits();
  }

  updateScrollLimits() {
    this.maxScroll =
      this.carousel.nativeElement.scrollWidth -
      this.carousel.nativeElement.clientWidth;
    this.endReached = this.carousel.nativeElement.scrollLeft >= this.maxScroll;
    this.startReached = this.carousel.nativeElement.scrollLeft === 0;
  }

  getElementsArray(): HTMLElement[] {
    return Array.from(
      this.elementRef.nativeElement.querySelectorAll('.carousel>*:not(.space)')
    );
  }

  scrollItems(direction: number) {
    this.updateNumberOfItemsToScroll(this.images);

    const scrollIncrement =
      direction > 0 ? this.numberOfItemsToScroll : -this.numberOfItemsToScroll;
    const newItemIdx = this.clamp(
      this.currentItemIdx + scrollIncrement,
      0,
      this.images.length - 1
    );

    const el = this.images[newItemIdx];

    this.setScroll(el.offsetLeft - this.carousel.nativeElement.offsetLeft);
  }
  clamp(num: number, min: number, max: number) {
    return num <= min ? min : num >= max ? max : num;
  }
}
