import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { sortBy } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import { MetaSearchApiService } from '../meta-search-api.service';
import {
  FlatResult,
  MetaSearchDetailsQuery,
  OrgTypeEnum,
} from '../meta-search.interfaces';

@Component({
  selector: 'na-storefront-meta-search-result-detail',
  templateUrl: './meta-search-result-detail.component.html',
  styleUrls: ['./meta-search-result-detail.component.scss'],
})
export class MetaSearchResultDetailComponent implements OnInit, OnDestroy {
  searchImageData: any;

  errorMessage: string = 'Item cannot be found';
  relatedArtworks: any;
  placeholderTitle: string = 'Cypress';
  placeholderImage: string =
    '../../assets/meta-search/meta-search-placeholder.webp';
  sourceName: string = '';
  isTooltipVisible: boolean = false;
  placeholderImageTooltip: boolean = false;
  dummyCopyrightText: string =
    'This item may be protected by copyright and/or related rights. You are free to use this item in any way that is permitted by the copyright and related rights legislation that applies to your use. For other uses you may need to obtain permission from the rights-holder(s). Provision of access to this item does not constitute a representation or warranty of any kind, including as to the accuracy of its contents.';

  public renderResults$!: Observable<FlatResult[]>;
  public artworkId: string = '';
  public allArtworks: FlatResult[] = [];
  public nextArtworks: FlatResult[] = [];
  public noResults: boolean = false;

  private _selectedOrganisationTypes$ = new BehaviorSubject<OrgTypeEnum[]>([]);
  public selectedOrganisationTypes$ = this._selectedOrganisationTypes$;
  public resultDetailSubscription: Subscription = new Subscription();

  artworksConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    variableWidth: true,
    swipe: false,
  };

  @ViewChild('carousel1') carousel1: ElementRef | any;
  @ViewChild('tooltip') imageTooltip: ElementRef | undefined;

  constructor(
    public service: MetaSearchApiService,
    private readonly ar: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.resultDetailSubscription = combineLatest([
      this.ar.queryParams,
      this.service.flatResults$,
    ])
      .pipe(
        map(([queryParams, results]) => {
          const result: FlatResult | undefined = results.find(
            (o) => o.hash === queryParams['id']
          );
          if (queryParams['id'] && results.length && !result) {
            throw new Error('Data not Found');
          }
          return result;
        })
      )
      .subscribe(
        (result: any) => {
          // this.service.searchImageData.next(val);
          this.noResults = false;
          if (result) {
            this.service
              .metaSearchDetail(result.connector.path, result)
              .subscribe(
                (res: MetaSearchDetailsQuery) => {
                  this.searchImageData = res;
                  this.service._isLoading$.next(false);
                  this.noResults = false;
                },
                (err) => {
                  if (!this.searchImageData) {
                    this.noResults = true;
                    this.service._isLoading$.next(false);
                  }
                }
              )
          }
        },
        (err) => {
         this.service._isLoading$.next(false);
         this.noResults = true;
        }
      );

    this.ar.queryParams.subscribe((params) => {
      this.sourceName = params['source'];
    });

    this.renderResults$ = combineLatest([
      this.service.flatResults$,
      this.selectedOrganisationTypes$,
    ]).pipe(
      debounceTime(250),
      map(([results, selectedOrgTypes]) =>
        sortBy(results, 'score')
          .reverse()
          .filter((result, index) => {
            if (
              selectedOrgTypes.length &&
              !selectedOrgTypes.includes(result.connector.organization_type)
            ) {
              return false;
            }
            return true;
          })
      )
    );

    this.artworkId = this.ar.snapshot.queryParams['id']
      ? this.ar.snapshot.queryParams['id']
      : this.artworkId;

    this.renderResults$.subscribe((res: FlatResult[]) => {
      this.allArtworks = res;
      this.getNextArtworks();
    });

    this.service.isMetaSearchPage = false;
  }

  afterChange(data: any) {
    if (data.currentSlide === 0) {
      this.carousel1.el.nativeElement.firstChild.style.border = 'solid #D3D1D1';
      this.carousel1.el.nativeElement.firstChild.style.pointerEvents = 'none';
    } else {
      this.carousel1.el.nativeElement.firstChild.style.border = 'solid black';
      this.carousel1.el.nativeElement.firstChild.style.pointerEvents =
        'visible';
    }
  }

  displayTooltip(action: string): void {
    action == 'enter'
      ? (this.isTooltipVisible = true)
      : (this.isTooltipVisible = false);
  }

  mouseMove(event: any): void {
    this.imageTooltip?.nativeElement.setAttribute(
      'style',
      `top: ${event.clientY + 20}px; left: ${event.clientX + 20}px`
    );
  }

  getNextArtworks() {
    this.nextArtworks = [];
    const index: number = this.allArtworks.findIndex(
      (el: FlatResult) => el.hash == this.artworkId
    );

    const len: number = this.allArtworks.length;
    let nextIndex: number = index + 1;
    let lastIndex: number = nextIndex + 20;

    // If selected item is last item
    if (nextIndex === len) {
      nextIndex = 0;
      lastIndex = 20;
    }
    lastIndex = lastIndex > len ? len : lastIndex;

    if (this.nextArtworks.length < 20 && this.allArtworks.length > 20) {
      this.nextArtworks = [
        ...this.allArtworks.slice(nextIndex, lastIndex),
        ...this.allArtworks.slice(0, 20 - (lastIndex - nextIndex)),
      ];
    } else {
      this.nextArtworks = this.allArtworks.slice(nextIndex, lastIndex);
    }
  }

  onArtworkClick(id: string) {
    this.artworkId = id;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
    this.service._isLoading$.next(true);
    this.searchImageData = false;
    this.noResults = true;
    this.getNextArtworks();
  }

  ngOnDestroy() {
    this.resultDetailSubscription.unsubscribe();
  }
}
