<!-- Meta search header -->
<div class="na-meta-search-header">
  <a class="logo" [routerLink]="[]">
    <img src="assets/Logo.svg" width="82" height="76" alt="Logo" loading="lazy" />
  </a>
  <a href="https://navigating.art/" target="_blank" class="navigating-art-link">
    Navigating.art<img height="15" width="15" class="link-arrow" src="assets/meta-search/link-arrow.webp" />
  </a>
</div>

<div class="page-position">
  <router-outlet></router-outlet>
</div>

<na-meta-search-footer></na-meta-search-footer> 