import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';

import { SanitizedMarkdownPipe } from './sanitized-markdown.pipe';

import { MetaSearchFooterComponent } from './meta-search-footer/meta-search-footer.component';

@NgModule({
  declarations: [
    MetaSearchFooterComponent,
    SanitizedMarkdownPipe
  ],
  imports: [
    CommonModule,
    MarkdownModule
  ],
  exports: [
    MetaSearchFooterComponent,
    SanitizedMarkdownPipe
  ]
})
export class SharedModule { }
