<div class="bottom-part display-flex">
    <div class="title-part">
      <h1 class="search-title-text title-margin">
        Together we can contribute to a more transparent artworld
      </h1>
      <p class="font-medium">
        Navigating.art’s search tool combines multiple data sources into one, making your research easier & more
        reliable.
        <a class="terms-conditions unstyled-anchor-link">T&Cs apply</a>.
      </p>
    </div>
    <div class="na-line"></div>  
  </div>