import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MetaSearchResultDetailComponent } from "./meta-search-result-detail/meta-search-result-detail.component";
import { MetaSearchComponent } from "./meta-search/meta-search.component";
import { MetaSearchTermsConditionsComponent } from "./meta-search-terms-conditions/meta-search-terms-conditions.component";

const routes: Routes = [
  {
    path: "",
    component: MetaSearchComponent,
  },
  {
    path: "detail",
    component: MetaSearchResultDetailComponent,
  },
  {
    path: "terms-and-conditions",
    component: MetaSearchTermsConditionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MetaSearchRoutingModule {}
